.gallery_photo{
    width: 100%;
    height: 400px !important;
    border-radius: 7px;
    cursor: pointer;
    @media screen and (max-width:576px) {
        height: 250px !important;
    }
}
.photo_open{
    position: fixed;
    background-color: #00000092;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 6%;
    width: 100%;
    height: 95vh !important;
    z-index: 1;
    img{
        width: 90%;
        height: 90% !important;
        border-radius: 7px;
        @media screen and (max-width:768px) {
            height: auto !important;
        }
    }
    .photo_prev{
        position: absolute;
        background: #26444F;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 1.5%;
        color: #fff;
        width: 40px;
        height: 40px;
        font-size: 24px;
        border-radius: 50%;
        cursor: pointer;
        @media screen and (max-width:768px) {
            bottom: 10%;
            left: 25%;
        }
        @media screen and (max-width:576px) {
            bottom: 15%;
        }
    }
    .photo_next{
        position: absolute;
        background: #26444F;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 1.5%;
        color: #fff;
        width: 40px;
        height: 40px;
        font-size: 24px;
        border-radius: 50%;
        cursor: pointer;
        @media screen and (max-width:768px) {
            bottom: 10%;
            right: 25%;
        }
        @media screen and (max-width:576px) {
            bottom: 15%;
        }
    }
    .close_photo{
        position: absolute;
        top: 5%;
        right: 1%;
        color: #fff;
        font-size: 24px;
        cursor: pointer;
        @media screen and (max-width:768px) {
            right: 5%;
        }
        @media screen and (max-width:380px) {
            top: 10%;
        }
    }
}