#home_photo{
    position: relative;
    margin-top: 105px;
    font-family: "Montserrat", Sans-serif;
    img{
        width: 100%;
        height: calc(100vh - 105px);
        user-select: none;
        pointer-events: none;
        @media screen and (max-width:992px) {
            height: 500px;
        }
        @media screen and (max-width:768px) {
            height: 400px;
        }
        @media screen and (max-width:576px) {
            height: 300px;
        }
    }
    #home_photo_title_text{
        position: absolute;
        top: 50%;
        left: 5%;
        color: #fff;
        user-select: none;
        animation: home_text 1s forwards;
        #title{
            font-size: 48px;
            font-weight: 500;
            margin-left: 10.5vw;
            text-shadow: 2px 2px 2px #ff0000;
            span{
                color: orangered;
                font-weight: bold;
                margin-left: -10.5vw;
                text-shadow: none;
            }
            @media screen and (max-width:768px) {
                font-size: 32px;
            }
            @media screen and (max-width:495px) {
                font-size: 24px;
            }
        }
        #text{
            width: 40%;
            font-size: 20px;
            text-shadow: 2px 2px 2px #ff0000;
            text-transform: uppercase;
            @media screen and (max-width:1200px) {
                width: 80%;
            }
            @media screen and (max-width:992px) {
                width: 100%;
                font-size: 16px;
            }
            @media screen and (max-width:768px) {
                font-size: 14px;
            }
            @media screen and (max-width:576px) {
                font-size: 12px;
            }
            @media screen and (max-width:365px) {
                font-size: 10px;
            }
            @media screen and (max-width:320px) {
                font-size: 9px;
            }
        }
    }
    @keyframes home_text {
        from{
            top: 50%;
        }
        to{
            top: 10%;
        }
    }
    #home_photo_read_more{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        animation: home_more 1s forwards;
        span{
            background-color: orangered;
            padding: 10px 15px;
            border: 2px solid orangered;
            border-radius: 50px;
            color: #fff;
            transition: all 0.5s linear;
            cursor: pointer;
            &:hover{
                background-color: transparent;
            }
        }
        @media screen and (max-width:992px) {
            display: none;
        }
    }
    @keyframes home_more {
        from{
            bottom: 0;
        }
        to{
            bottom: 10%;
        }
    }
}