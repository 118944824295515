h3.item_title {
  color: #54595F;
  font-family: "Poppins", Sans-serif;
}

.projects_photo {
  height: 100%;
  border-radius: 7px;
}

.new_projects_img {
  width: 100%;
  height: 500px;
}

.new_projects_img_first {
  border-top: 1.7px solid gray;
  border-left: 3px solid #232323;
  padding-top: 10px;
  padding-left: 10px;
}

.link_gallery a {
  color: orangered;
  font-weight: 500;
}/*# sourceMappingURL=projects.css.map */