h3.item_title{
    color: #54595F;
    font-family: "Poppins", Sans-serif;
}
.contact_us_inputes{
    input{
        width: 100%;
        margin-bottom: 30px;
        padding: 10px 0;
        padding-left: 15px;
        border: none;
        border-bottom: 2px solid #54595F;
        outline: none;
        &:first-child{
            border: 2px solid #54595F;
            border-bottom: none;
        }
    }
    textarea{
        width: 100%;
        margin-bottom: 30px;
        padding: 10px 0;
        padding-left: 15px;
        border: 2px solid #54595F;
        border-top: none;
        outline: none;
    }
    #contact_us_button{
        text-align: center;
        button{
            background-color: orangered;
            padding: 10px 25px;
            color: #fff;
            border: 2px solid orangered;
            border-radius: 50px;
            transition: 0.7s ease;
            &:hover{
                background-color: transparent;
                color: #000;
            }
        }
    }
}
.contact_us_map_info{
    #google_map{
        position: relative;
        iframe{
            width: 100%;
            height: 300px;
            @media screen and (max-width:1200px) {
                margin-top: 30px;
            }
        }
        #map_href{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 65%;
            z-index: 5;
            @media screen and (max-width:1200px) {
                margin-top: 30px;
                height: 57%;
            }
        }
        #map_href2{
            position: absolute;
            left: 0;
            top: 65%;
            width: 93%;
            height: 16%;
            z-index: 5;
            @media screen and (max-width:1200px) {
                width: 94.5%;
            }
            @media screen and (max-width:992px) {
                width: 93%;
            }
            @media screen and (max-width:768px) {
                width: 90%;
            }
            @media screen and (max-width:576px) {
                width: 89%;
            }
        }
        #map_href3{
            position: absolute;
            left: 50%;
            top: 81%;
            width: 86%;
            height: 17%;
            transform: translateX(-50%);
            z-index: 5;
            @media screen and (max-width:1200px) {
                width: 89%;
            }
            @media screen and (max-width:992px) {
                width: 86%;
            }
            @media screen and (max-width:768px) {
                width: 80%;
            }
            @media screen and (max-width:576px) {
                width: 78%;
            }
        }
    }
    .contact_us_infos{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .contac_us_info{
            margin-top: 30px;
            text-align: center;
            h3{
                span{
                    margin-right: 15px;
                    color: orangered;
                    font-weight: bold;
                }
            }
            h6{
                margin-left: 15px;
                color: #54595F;
                span{
                    color: orangered;
                    font-weight: bold;
                }
                a{
                    line-height: 25px;
                    color: orangered;
                }
            }
        }
        @media screen and (max-width:576px) {
            display: block;
        }
    }
}