h3.item_title {
  color: #54595F;
  font-family: "Poppins", Sans-serif;
}

.home_photo1 {
  border-radius: 15px;
  box-shadow: 0px 0px 35px 5px #54595F;
}

.about_us_title_text h2 {
  color: #54595F;
  line-height: 45px;
}
@media screen and (max-width: 1200px) {
  .about_us_title_text {
    text-align: center;
  }
}

.num_animations {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 30px;
}
.num_animations div div {
  color: orangered;
  font-size: 32px;
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .num_animations {
    display: block;
    margin-top: 0;
  }
  .num_animations div {
    margin-top: 30px;
  }
  .num_animations div div {
    margin-top: 0;
  }
}

.banks {
  margin-left: 25px;
}
.banks a {
  margin-left: 25px;
  color: orangered;
  font-weight: 500;
}/*# sourceMappingURL=about.css.map */