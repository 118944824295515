.page_content_img{
    width: 100%;
    height: 500px;
    border-radius: 15px;
}
.construction_img{
    width: 100%;
    height: 100%;
    border-radius: 15px;
}
#preview_box{
    position: fixed;
    background-color: #2323237c;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    z-index: 99;
    #preview{
        background-color: #fff;
        width: 70%;
        height: 70%;
        padding: 30px;
        border-radius: 7px;
        img{
            width: 30%;
            height: 200px;
            margin-bottom: 15px;
            border-radius: 7px;
            @media screen and (max-width:1400px) {
                width: 50%;
            }
            @media screen and (max-width:1200px) {
                width: 60%;
            }
            @media screen and (max-width:992px) {
                width: 70%;
            }
            @media screen and (max-width:768px) {
                width: 85%;
            }
            @media screen and (max-width:576px) {
                width: 100%;
            }
        }
        @media screen and (max-width:992px) {
            width: 80%;
        }
        @media screen and (max-width:768px) {
            width: 90%;
        }
        @media screen and (max-width:576px) {
            width: 100%;
        }
    }
    #close{
        position: absolute;
        top: 50px;
        right: 30px;
        color: #fff;
        font-size: 32px;
        font-weight: bold;
        cursor: pointer;
    }
}