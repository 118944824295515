.page_content_img {
  width: 100%;
  height: 500px;
  border-radius: 15px;
}

.construction_img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

#preview_box {
  position: fixed;
  background-color: rgba(35, 35, 35, 0.4862745098);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  z-index: 99;
}
#preview_box #preview {
  background-color: #fff;
  width: 70%;
  height: 70%;
  padding: 30px;
  border-radius: 7px;
}
#preview_box #preview img {
  width: 30%;
  height: 200px;
  margin-bottom: 15px;
  border-radius: 7px;
}
@media screen and (max-width: 1400px) {
  #preview_box #preview img {
    width: 50%;
  }
}
@media screen and (max-width: 1200px) {
  #preview_box #preview img {
    width: 60%;
  }
}
@media screen and (max-width: 992px) {
  #preview_box #preview img {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  #preview_box #preview img {
    width: 85%;
  }
}
@media screen and (max-width: 576px) {
  #preview_box #preview img {
    width: 100%;
  }
}
@media screen and (max-width: 992px) {
  #preview_box #preview {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  #preview_box #preview {
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  #preview_box #preview {
    width: 100%;
  }
}
#preview_box #close {
  position: absolute;
  top: 50px;
  right: 30px;
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  cursor: pointer;
}/*# sourceMappingURL=pages_content.css.map */