h3.item_title{
    color: #54595F;
    font-family: "Poppins", Sans-serif;
}
#services_title{
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    span{
        color: orangered;
    }
    @media screen and (max-width:768px) {
        font-size: 32px;
    }
    @media screen and (max-width:576px) {
        font-size: 24px;
    }
    @media screen and (max-width:360px) {
        font-size: 20px;
    }
}
.services_box_items{
    a{
        color: inherit;
    }
    .services_item{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 280px;
        text-align: center;
        padding: 30px 10px;
        border: 1px solid #54595F;
        border-radius: 7px;
        svg{
            color: orangered;
            margin: 0 auto;
            font-size: 48px;
        }
        .services_item_title{
            font-size: 24px;
            font-weight: 600;
        }
    }
}