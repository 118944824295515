#header {
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 105px;
  padding: 0 !important;
  font-weight: 600;
  z-index: 100;
}
#header .navbar-brand {
  display: flex;
  align-items: center;
  width: 100px;
  height: 100%;
  margin: 0;
}
#header .navbar-brand img {
  width: 100%;
  height: 100%;
}
#header #nav_toogler {
  background-color: orangered !important;
  box-shadow: none;
}
#header .dropdown-menu {
  background-color: #fff;
  border: none;
}
@media screen and (min-width: 1199px) {
  #header .dropdown-menu {
    margin-left: -50px;
  }
}
@media screen and (min-width: 1199px) {
  #header .header_armenian_font {
    font-size: 14px;
  }
}
#header .nav_links {
  min-height: 105px;
}
#header .nav_links a {
  display: flex;
  align-items: center;
  margin: 0 15px;
}
@media screen and (max-width: 1199px) {
  #header .nav_links {
    padding: 15px 0;
  }
  #header .nav_links a {
    margin: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 1199px) {
  #header {
    padding: 15px 0;
  }
}
#header * {
  color: #54595F;
  transition: color 0.5s ease;
}
#header *:hover {
  color: orangered;
}

.navbar-collapse.show {
  padding-bottom: 10px !important;
}/*# sourceMappingURL=header.css.map */