h3.item_title{
    color: #54595F;
    font-family: "Poppins", Sans-serif;
}
.home_photo1{
    border-radius: 15px;
    box-shadow: 0px 0px 35px 5px #54595F;
}
.about_us_title_text{
    h2{
        color: #54595F;
        line-height: 45px;
    }
    @media screen and (max-width:1200px) {
        text-align: center;
    }
}
.num_animations{
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 30px;
    div{
        div{
            color: orangered;
            font-size: 32px;
            font-weight: bold;
        }
    }
    @media screen and (max-width:768px) {
        display: block;
        margin-top: 0;
        div{
            margin-top: 30px;
            div{
                margin-top: 0;
            }
        }
    }
}
.banks{
    margin-left: 25px;
    a{
        margin-left: 25px;
        color: orangered;
        font-weight: 500;
    }
}